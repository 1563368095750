<script>
import fileTypeIcons from './fileTypeIcons';

const fileNameOverride = {
    platformio: 'platformio',
};

const fileTypeOverride = {
    h: 'c',
    ino: 'cpp',
};

export default {
    name: 'FileName',

    props: {
        fileName: {
            type: String,
            required: true,
        },

        filePath: {
            type: String,
            required: true,
        },

        defaultFile: {
            type: String,
            default: () => 'fileSvg',
        },
    },

    data() {
        return {
            fileIconKeys: [],
        };
    },

    created() {
        this.fileIconKeys = Object.keys(this.$options.components);
    },

    render(createElement) {
        const constructIconName = (fileType) => {
            const fileIconKey = `${fileType}Svg`;
            return this.fileIconKeys.includes(fileIconKey)
                ? fileIconKey
                : this.defaultFile;
        };

        const createFileIcon = (filePath) => {
            const fileNameWithExt = filePath.split('/').pop();
            let [fileNameWithoutExt, fileType] = fileNameWithExt.split('.'); // eslint-disable-line prefer-const

            if (fileNameOverride[fileNameWithoutExt]) {
                fileType = fileNameOverride[fileNameWithoutExt];
            }

            if (fileTypeOverride[fileType]) {
                fileType = fileTypeOverride[fileType];
            }

            return createElement(constructIconName(fileType), {
                class: 'h-4 mr-2 pointer-events-none',
            });
        };

        return createElement('span', {
            class: 'flex items-center relative z-10 text-gray-300 cursor-pointer py-1 pr-2 text-sm',
        }, [
            createFileIcon(this.filePath),
            this.fileName,
        ]);
    },

    components: {
        ...fileTypeIcons,
    },
};
</script>
